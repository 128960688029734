import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';

const Header = () => {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`w-full px-4 py-4 md:p-4 h-14 z-10 transition-all duration-300 ${isSticky ? 'fixed top-0 left-0 bg-black shadow-lg z-30' : 'absolute bg-transparent'}`}>
            <div className="flex justify-between items-center max-w-screen-xl mx-auto">
                <div className="text-teal-400 text-lg md:text-xl font-mono">
                    <h1>
                        <Link to={'home'}
                            smooth={true}
                            duration={500}
                            className="cursor-pointer">
                            DmytroKozin<span className="text-indigo-400 animate-pulse">._</span>
                        </Link>
                    </h1>
                </div>
                <nav className="text-sm font-mono flex space-x-4 md:space-x-8 hidden sm:block">
                    <Link to={'home'}
                        smooth={true}
                        duration={500}
                        className="cursor-pointer text-gray-400 hover:text-white">
                        {'// home'}
                    </Link>
                    <Link to={'expertise'}
                        smooth={true}
                        duration={500}
                        className="cursor-pointer text-gray-400 hover:text-white">
                        {'// expertise'}
                    </Link>
                    <Link to={'work'}
                        smooth={true}
                        duration={500}
                        className="cursor-pointer text-gray-400 hover:text-white">
                        {'// work'}
                    </Link>
                    <Link to={'experience'}
                        smooth={true}
                        duration={500}
                        className="cursor-pointer text-gray-400 hover:text-white">
                        {'// experience'}
                    </Link>
                    <Link to={'contact'}
                        smooth={true}
                        duration={500}
                        className="cursor-pointer text-gray-400 hover:text-white">
                        {'// contact'}
                    </Link>
                    <a
                        href="/SENIOR_DEVELOPER_DMYTRO_KOZIN.pdf"
                        download="CV.pdf"
                        className="cursor-pointer text-gray-400 hover:text-white"
                    >
                        {'// Download CV'}
                    </a>
                </nav>
            </div>
        </header>
    );
};

export default Header;
